import {
	faAward,
	faChild,
	faEnvelope,
	faHandshake,
	faUserTie,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { Fade } from "react-reveal"
import { FormWrapper } from "../components/Forms"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { StickyContainer, Sticky } from "react-sticky"
import { OutboundLink } from "gatsby-plugin-google-analytics"

const data = {
	text: [
		"Recruiter Vermittlung",
		"Recruiter finden, die Ahnung haben",
		"Termin buchen",
	],
	richText: [
		"<p>Hier finden Sie wenig Text, dafür aber einen guten Tipp, wie Sie einen professionellen Recruiter in Festanstellung für Ihr Unternehmen finden – ganz einfach, bei unserem Tochterunternehmen, der VIPCRUITER GmbH:</p>",
	],
	boolean: [false, false, true],
}

const Page = () => {
	return (
		<Layout>
			<Seo
				title="Recruiter Vermittlung - Recruiter für Ihre Firma"
				description="Recruiter Vermittlung: ✔️ Recruiter einfach finden ✔️ in Festanstellung ✔️ für Ihr Unternehmen ✔️ Kosten hier anfragen!"
			/>
			<StickyContainer className="w-full">
				<div className="bg-white w-full overflow-x-hidden">
					<div className="sectionwidth sectionpadding grid grid-cols-1 lg:grid-cols-4 gap-24">
						<div className="lg:col-span-3">
							<h1
								className={
									"h1_5 font-bold text-white text-center p-4 rounded-full " +
									(data.boolean[0] ? "bg-primary" : "") +
									(data.boolean[1] ? "bg-secondary-800" : "") +
									(data.boolean[2] ? "bg-primary-900" : "")
								}
							>
								{data.text[0]}
								<span className="block h3">{data.text[1]}</span>
							</h1>
							<StaticImage
								className="border-2 my-8 border-primary rounded-lg shadow-lg w-full sm:h-80"
								quality={100}
								src="../images/recruitervermittlung.png"
								alt="Karriere Website"
								placeholder="tracedSVG"
							/>
							<div
								className="prose max-w-full mb-4"
								dangerouslySetInnerHTML={{ __html: data.richText[0] }}
							></div>
							<Fade right delay={1000}>
								<OutboundLink
									href="https://www.recruiter-finden.de"
									target="_blank"
								>
									<StaticImage
										src="../images/rf_logo.png"
										alt="Recruiter finden"
										placeholder="tracedSVG"
										layout="fullWidth"
									></StaticImage>
								</OutboundLink>
							</Fade>
						</div>
						<div className="hidden lg:block">
							<Sticky topOffset={-70}>
								{({ style, isSticky, distanceFromBottom }) => (
									<div
										className=""
										style={{
											...style,
											marginTop: isSticky
												? distanceFromBottom > 120
													? 120 + "px"
													: distanceFromBottom + "px"
												: "",
										}}
									>
										<div className="bg-white rounded-lg shadow-xl border-2 border-secondary p-4 mx-auto w-60">
											<h2 className="text-primary font-bold text-center mb-4">
												Unsere Team-Expertise im Recruiting:
											</h2>
											<div className="space-y-6">
												<div className="flex items-start text-sm">
													<FontAwesomeIcon
														icon={faAward}
														className="mr-2 text-secondary"
														size="lg"
													/>
													<span>
														<strong>46 Jahre</strong> im operativen Recruiting
													</span>
												</div>
												<div className="flex items-start text-sm">
													<FontAwesomeIcon
														icon={faAward}
														className="mr-2 text-secondary"
														size="lg"
													/>
													<span>
														<strong>20 Jahre</strong> in der beratenden
														Recruiting-Optimierung
													</span>
												</div>
												<div className="flex items-start text-sm">
													<FontAwesomeIcon
														icon={faAward}
														className="mr-2 text-secondary"
														size="lg"
													/>
													<span>
														In über <strong>30 verschiedenen Branchen</strong>
													</span>
												</div>
											</div>
										</div>
									</div>
								)}
							</Sticky>
						</div>
					</div>
				</div>
			</StickyContainer>

			<div className="bg-gray-100 w-full" id="angebot">
				<div className="sectionwidth sectionpadding">
					<div className="p-8 bg-white shadow-xl">
						<h2 className="h1_5 text-primary font-bold text-center mb-4">
							<FontAwesomeIcon icon={faEnvelope} /> Wir unterbreiten Ihnen gerne
							ein Angebot!
						</h2>
						<p className="text-center mb-8">
							Kreuzen Sie die Punkte an, die Sie interessieren, füllen Sie das
							Kontaktformular aus & klicken Sie auf SENDEN.
						</p>
						<FormWrapper
							name="Angebot anfordern"
							successMessage="Vielen Dank, dass Sie uns kontaktiert haben! Wir werden uns schnellstmöglich bei Ihnen zurückmelden. Schöne Grüße, Ihr Team von Internes-Recruiting.de"
							subject={data.text[8]}
						>
							<div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
								{" "}
								<div className="bg-primary rounded-lg flex flex-col gap-2 p-3 text-white">
									<span className="font-bold">Bewerbergewinnung</span>
									<label className="inline-flex items-center text-xs sm:text-base">
										<input
											type="checkbox"
											name="Karriere Website"
											className="mr-2 checkbox"
											value="angekreuzt"
										/>
										Karriere-Website
									</label>
									<label className="inline-flex items-center text-xs sm:text-base">
										<input
											type="checkbox"
											name="E-Recruiting-Analyse"
											className="mr-2 checkbox"
											value="angekreuzt"
										/>
										E-Recruiting-Analyse
									</label>
									<label className="inline-flex items-center text-xs sm:text-base">
										<input
											type="checkbox"
											name="Active Sourcing"
											className="mr-2 checkbox"
											value="angekreuzt"
										/>
										Active Sourcing
									</label>
								</div>
								<div className="bg-secondary-800 rounded-lg flex flex-col gap-2 p-3 text-white">
									<span className="font-bold">Recruiting Beratung</span>

									<label className="inline-flex items-center text-xs sm:text-base">
										<input
											type="checkbox"
											name="Recruiting Strategie"
											className="mr-2 checkbox"
											value="angekreuzt"
										/>
										Recruiting Strategie
									</label>
									<label className="inline-flex items-center text-xs sm:text-base">
										<input
											type="checkbox"
											name="Recruiting Impulse"
											className="mr-2 checkbox"
											value="angekreuzt"
										/>
										Recruiting Impulse
									</label>
									<label className="inline-flex items-center text-xs sm:text-base">
										<input
											type="checkbox"
											name="Recruiting Seminare"
											className="mr-2 checkbox"
											value="angekreuzt"
										/>
										Recruiting Seminare
									</label>
								</div>
								<div className="bg-primary-900 rounded-lg flex flex-col gap-2 p-3 text-white">
									<span className="font-bold">Bewerbergewinnung</span>
									<label className="inline-flex items-center text-xs sm:text-base">
										<input
											type="checkbox"
											name="Motivation & KPI´s"
											className="mr-2 checkbox"
											value="angekreuzt"
										/>
										Motivation & KPI´s
									</label>
									<label className="inline-flex items-center text-xs sm:text-base">
										<input
											type="checkbox"
											name="Recruitervermittlung"
											className="mr-2 checkbox"
											value="angekreuzt"
										/>
										Recruitervermittlung
									</label>
									<label className="inline-flex items-center text-xs sm:text-base">
										<input
											type="checkbox"
											name="Recruiter mieten"
											className="mr-2 checkbox"
											value="angekreuzt"
										/>
										Recruiter mieten
									</label>
								</div>
								<div className="flex flex-col gap-2 p-3">
									<span className="font-bold">Sonstiges</span>
									<label className="inline-flex items-center text-xs sm:text-base">
										<input
											type="checkbox"
											name="Individuelles Angebot"
											className="mr-2 checkbox"
											value="angekreuzt"
										/>
										Individuelles Angebot
									</label>
								</div>
								<label className="flex flex-col text-gray-500">
									Name*
									<input
										className="input text-black"
										type="text"
										name="name"
										required
									/>
								</label>
								<label className="flex flex-col text-gray-500">
									Geschäftliche E-Mail*
									<input
										className="input text-black"
										type="email"
										name="email"
										required
									/>
								</label>
								<label className="flex flex-col text-gray-500">
									Telefon
									<input
										required
										className="input text-black"
										type="text"
										name="Telefon"
									/>
								</label>
								<label className="flex flex-col text-gray-500">
									Firma
									<input
										className="input text-black"
										type="text"
										name="Firma"
									/>
								</label>
							</div>

							<label className="flex flex-col text-gray-500 mb-3 mt-2">
								Nachricht
								<textarea
									className="input text-black"
									rows="8"
									name="nachricht"
								/>
							</label>
						</FormWrapper>
					</div>
				</div>
			</div>

			<div className="bg-white w-full">
				<div className="sectionwidth sectionpadding">
					<h2 className="h1_5 mb-8 font-bold text-primary text-center w-full">
						Weitere Recruitinglösungen
					</h2>

					<div className="flex flex-col md:flex-row -mx-2 sm:mx-0">
						<div className="py-8 px-3 text-center space-y-4 flex-grow bg-primary text-white transform transition-transform hover:z-10 hover:scale-105">
							<FontAwesomeIcon icon={faChild} size="3x" />
							<h3 className="h3 font-bold">Bewerbergewinnung</h3>
							<p>
								So erhalten Sie zukünftig{" "}
								<strong>schneller &amp; mehr passende Bewerber</strong>. Unsere
								Analyse zeigt Ihnen Lösungswege für eine erfolgreichere
								Personalgewinnung auf.
							</p>
							<div className="flex flex-col gap-2 items-center">
								<a
									href="/karriere-website-kosten-angebot"
									className="text-primary bg-white p-2 font-bold transition-all rounded-full border border-white hover:bg-primary hover:text-white"
								>
									Karriere-Website
								</a>
								<a
									href="/e-recruiting-analyse-kosten-sparen"
									className="text-primary bg-white p-2 font-bold transition-all rounded-full border border-white hover:bg-primary hover:text-white"
								>
									E-Recruiting-Analyse
								</a>
								<a
									href="/active-sourcing-konzept-zum-erfolg"
									className="text-primary bg-white p-2 font-bold transition-all rounded-full border border-white hover:bg-primary hover:text-white"
								>
									Active-Sourcing
								</a>
							</div>
						</div>

						<div className="py-8 px-3 text-center space-y-4 flex-grow bg-secondary-800 text-white transform transition-transform hover:z-10 hover:scale-105">
							<FontAwesomeIcon icon={faHandshake} size="3x" />
							<h3 className="h3 font-bold">Recruiting Beratung</h3>
							<p>
								So lösen Sie Ihre Recruitingthemen nachhaltig, erhalten{" "}
								<strong>neue</strong> <strong>Impulse</strong> und bringen Ihr
								Recruitingteam durch <strong>Schulungen</strong> und{" "}
								<strong>Workshops</strong> nach vorne.
							</p>

							<div className="flex flex-col gap-2 items-center">
								<a
									href="/recruiting-strategie-kmu"
									className="text-secondary-800 bg-white p-2 font-bold transition-all rounded-full border border-white hover:bg-secondary-800 hover:text-white"
								>
									Recruiting Strategie
								</a>
								<a
									href="/recruiting-impulse-neue-ideen"
									className="text-secondary-800 bg-white p-2 font-bold transition-all rounded-full border border-white hover:bg-secondary-800 hover:text-white"
								>
									Recruiting Impulse
								</a>
								<a
									href="/recruiting-seminare-fuer-recruiter"
									className="text-secondary-800 bg-white p-2 font-bold transition-all rounded-full border border-white hover:bg-secondary-800 hover:text-white"
								>
									Recruiting Seminare
								</a>
							</div>
						</div>

						<div className="py-8 px-3 text-center space-y-4 flex-grow bg-primary-900 text-white transform transition-transform hover:z-10 hover:scale-105">
							<FontAwesomeIcon icon={faUserTie} size="3x" />
							<h3 className="h3 font-bold">Recruiting in Aktion</h3>
							<p>
								So wird die <strong>Führung</strong> Ihres{" "}
								<strong>Recruitingteams</strong> sachlich, motivierend und
								zielführend. Wenn Sie einen <strong>Recruiter suchen</strong>,
								finden wir diesen.
							</p>

							<div className="flex flex-col gap-2 items-center">
								<a
									href="/recruiting-kpi-motivation"
									className="text-primary-900 bg-white p-2 font-bold transition-all rounded-full border border-white hover:bg-primary-900 hover:text-white"
								>
									Recruiting KPI´s & Motivation
								</a>
								<a
									href="/recruiter-vermittlung-festanstellung"
									className="text-primary-900 bg-white p-2 font-bold transition-all rounded-full border border-white hover:bg-primary-900 hover:text-white"
								>
									Recruitervermittlung
								</a>
								<a
									href="/recruiter-mieten-und-rpo-laeuft"
									className="text-primary-900 bg-white p-2 font-bold transition-all rounded-full border border-white hover:bg-primary-900 hover:text-white"
								>
									Recruiter mieten
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Layout>
	)
}

export default Page
